<template>
    <div class="edit-photos" :class="{ havetitle: showTitle }">
        <!-- <div v-if="showTitle" class="title" v-html="`${$translate('profile_photo'.toUpperCase())}`" /> -->
        <div class="title">프로필 사진</div>
        <div class="photos">
            <div class="grid-col">
                <div
                    @click="onClickPhoto(idx)"
                    class="photo"
                    :class="{
                        border: !photo.url,
                        required: idx === 0 || idx === 1 || idx === 2,
                    }"
                    :key="photo.id"
                    v-for="(photo, idx) in photos"
                >
                    <div v-if="photo.url && !photo.$$deleted" class="img-cover" v-img-cover="photo.url">
                        <div class="badges">
                            <div class="badge-required">
                                <img :src="icon" class="badge-required-img" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="upload-img">
                        <img :src="icon" width="16px" height="16px" />
                    </div>
                </div>
            </div>
            <input
                ref="imageUploader"
                type="file"
                class="image-input display-none"
                accept="image/*"
                @change="onChangePhoto"
            />
        </div>
        <div class="photos-required" v-html="$translate('PHOTOS_REQUIRED')" />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'EditProfilePhotos',
    props: ['value', 'showTitle', 'icon'],
    // props : {
    //     value,
    //     showTitle : {
    //         type : Boolean,
    //         default : false,
    //     }
    // },
    data: () => ({
        photos: [],
        curPhoto: null,
        deletePhotoIds: [],
        photoChanged: false,
    }),
    watch: {
        photos: {
            handler() {
                let status = true
                if (this.photos[0].url && this.photos[1].url && this.photos[2].url) {
                    status = false
                }
                this.$emit('disabled', status)
            },
            deep: true,
        },
    },
    computed: {
        editable() {
            if (this.photos.filter(p => p.url !== null).length <= 3) {
                return false
            }
            return true
        },
    },
    methods: {
        init() {
            this.$numArray(12).forEach(idx => this.photos.push(this.value[idx] || { url: null }))
        },
        openGalleryFile() {
            // console.log(1)
            // const device = this.$store.getters.device
            // if (device) {
            //     this.$openGallery()
            //     console.log(2)
            // }
            // else {
            //     console.log(3)
            //     this.$refs.imageUploader.click()
            // }
            this.$refs.imageUploader.click()
        },
        onClickPhoto(index) {
            this.curPhoto = this.photos[index]
            const newUpload = !(this.curPhoto.id || this.curPhoto.url)
            this.curPhoto = this.photos[index]
            if (newUpload) {
                this.openGalleryFile()
                return
            }

            let buttons = [
                {
                    label: '사진 수정',
                    handler: () => {
                        if (!this.curPhoto.id || this.editable) {
                            this.openGalleryFile()
                        } else {
                            this.$toast.error('필수 사진은 반드시 3장이 필요합니다')
                        }
                    },
                },
                {
                    label: '사진 삭제',
                    handler: () => {
                        if (!this.curPhoto.id) {
                            this.removePhoto()
                            return
                        }

                        if (this.editable) {
                            this.removePhoto()
                        } else {
                            this.$toast.error('필수 사진은 반드시 3장이 필요합니다')
                        }
                    },
                },
                {
                    label: '메인 사진으로 지정',
                    handler: () => this.setMain(),
                },
            ]
            if (index === 0) buttons = buttons.splice(0, 2)
            else if (!this.curPhoto.confirmed) {
                buttons[2].class = 'inactive'
                buttons[2].handler = null
            }
            this.$actionSheet({ buttons })
        },
        onChangePhoto(event) {
            if (this.curPhoto.id) this.deletePhotoIds.push(this.curPhoto.id)

            let imgFile

            // if (this.$store.getters.device) {
            //     this.curPhoto.name = event.fileName
            //     imgFile = event.blob
            // } else {
            //     imgFile = event.target.files[0]
            //     this.$refs.imageUploader.value = ''
            //     this.curPhoto.name = imgFile.name
            // }
            // eslint-disable-next-line prefer-const
            imgFile = event.target.files[0]
            this.$refs.imageUploader.value = ''
            this.curPhoto.name = imgFile.name

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile,
                        mode: 'resized',
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return

                    const fReader = new FileReader()
                    fReader.onload = e => {
                        this.curPhoto.url = e.target.result
                        this.curPhoto.blob = croppedFile
                        this.curPhoto.$$deleted = false
                        console.log(this.curPhoto)
                        this.emit()
                    }
                    fReader.readAsDataURL(croppedFile)
                })
        },
        removePhoto() {
            if (this.curPhoto.id) this.deletePhotoIds.push(this.curPhoto.id)

            this.$set(this.curPhoto, 'id', null)
            this.$set(this.curPhoto, 'url', null)
            this.$set(this.curPhoto, '$$deleted', true)
            this.$set(this.curPhoto, 'blob', null)
            this.$set(this.curPhoto, 'name', null)
            this.emit()
        },
        async setMain() {
            if (this.curPhoto.position === 0) return

            if (!this.curPhoto.id) {
                this.$toast.error('심사가 통과되지 않은 사진은 메인으로 지정할 수 없습니다')
                return
            }

            try {
                this.$loading(true)
                await userService.updateMainPhoto(this.curPhoto.id)
                await this.$store.dispatch('loadMe')
                const recentPhotos = this.photos
                const photos = this.$store.getters.me.photos
                this.photos = []
                this.$numArray(12).forEach(idx => {
                    const photo = photos[idx]
                    if (!photo) return
                    const found = recentPhotos.find(p => p.id === photo.id)
                    if (found) this.photos.push(photo || { url: null })
                })

                this.$numArray(12).forEach(idx => {
                    const photo = recentPhotos[idx]
                    if (photo.blob && photo.blob !== null) {
                        this.photos.push(photo || { url: null })
                    }
                })

                this.$numArray(12).forEach(idx => {
                    if (!this.photos[idx]) {
                        this.photos.push({ url: null })
                    }
                })

                this.emit()
            } catch (e) {
                this.$toast.error(e.msg)
            }
            this.$loading(false)
        },
        emit() {
            this.$emit('input', this.photos)
            this.$emit('onChangeDeletePhotoIds', this.deletePhotoIds)
            this.$emit('photo', this.photos)

            this.photoChanged = true
        },
        onSave(key) {
            this.$emit('photo', this.photos)
            this.$emit('onChangeDeletePhotoIds', this.deletePhotoIds)
            this.photoChanged = true
            this.sendEvents()
        },
        sendEvents() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `${this.$store.getters.me.gender === 0 ? 'Male_3' : 'Female_3'}_Complete_Profile`,
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Complete_Profile',
                },
            })
        },
    },
    mounted() {
        this.init()
        this.$bus.$on('onRequestPhotoFromGallery', this.onChangePhoto)
    },
    beforeDestroy() {
        // if (this.photoChanged) this.$toast.success('SAVED')
        this.$bus.$off('onRequestPhotoFromGallery', this.onChangePhoto)
    },
}
</script>

<style lang="scss" scoped>
$edit-profile-padding: 16px;
.edit-photos {
    margin-top: 32px;
    padding: 0 $edit-profile-padding;

    .title {
        margin: 20px 0 12px 4px;
        color: #787878;
        font-size: 15px;
    }

    .photos-required {
        margin-top: 12px;
        margin-left: 4px;
        font-size: 13px;
        color: #f64f56;
        font-weight: 400;
    }

    $grid-no: 3;
    $grid-gap: 8px;

    .grid-col {
        $img-size-small: calc((100vw - #{$grid-gap} * (#{$grid-no} - 1) - #{$edit-profile-padding} * 2) / #{$grid-no});
        $img-size-large: calc((100vw - #{$grid-gap} - #{$edit-profile-padding} * 3) / 3);

        grid-template-columns: repeat($grid-no, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: $grid-gap;

        .photo {
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            width: $img-size-small;
            height: $img-size-small;
            background: white;
            border: solid 1px $grey-02;

            @include center;

            &.required {
                width: $img-size-large;
                height: $img-size-large;
                border: none;

                // &:nth-child(1) {
                //     grid-column: 1 / 3;
                //     grid-row: 1 / 3;
                // }

                // &:nth-child(2) {
                //     grid-column: 2 / 3;
                //     grid-row: 1 / 3;
                // }
            }

            &.border {
                border: 0.5px dashed #f64f56;
            }

            .upload-img {
                width: 28px;
                height: 28px;
                background: #f64f56;
                border-radius: 50%;
                @include center;
            }

            .img-cover {
                width: 100%;
                height: 100%;
            }

            .badges {
                position: absolute;
                right: 5%;
                bottom: 5%;
                display: flex;

                .badge-main,
                .badge-required,
                .badge-checking {
                    position: relative;
                    font-size: 10px;
                    color: white;
                    border-radius: 50px;
                    height: 24px;
                    width: 24px;

                    img {
                        position: relative;
                        width: 50%;
                        height: 50%;
                    }

                    @include center;
                    @include f-medium;
                }

                .badge-required {
                    // margin-right: 4px;
                    background-color: rgba(21, 19, 96, 0.8);
                }

                .badge-checking {
                    background-color: rgba(255, 61, 107, 0.5);
                }

                .badge-main {
                    background-color: rgba(138, 116, 255, 0.8);
                }
            }

            .material-icons {
                color: $grey-05;
                font-size: 24px;
            }
        }
    }
}
.havetitle {
    margin-top: 0;
}
</style>
