<template>
    <div class="edit-profile-detail">
        <div class="title">퍼스널 스토리</div>
        <div class="section m-0 p-0" v-for="(item, idx) in storyExamples" :key="idx">
            <div
                v-if="profile.user_personal_stories && profile.user_personal_stories.length > idx"
                class="written-story-icon"
                @click="onClickModify(idx)"
            >
                <img
                    class="complete"
                    :src="require(`@/assets/images/icons/story_complete.png`)"
                    alt="story-complete-icon"
                    width="28px"
                    height="28ox"
                />
            </div>
            <div
                v-if="profile.user_personal_stories && profile.user_personal_stories.length > idx"
                class="written-story-wrapper m-l-20 m-r-20 m-b-16"
                @click="onClickModify(idx)"
            >
                <div class="story-title f-14 f-medium p-t-22 m-l-16 m-r-16">
                    {{
                        personalStories &&
                        personalStories[profile.user_personal_stories[idx].personal_story_id - 1].name
                    }}
                </div>
                <div class="story-content lines-2 f-14">{{ profile.user_personal_stories[idx].content }}</div>
            </div>
            <div
                v-if="profile.user_personal_stories && profile.user_personal_stories.length <= idx"
                class="unwritten-story-icon"
                @click="onClickWrite(idx)"
            >
                <img
                    class="complete"
                    :src="require(`@/assets/images/icons/story_needed.png`)"
                    alt="story-needed-icon"
                    width="28px"
                    height="28ox"
                />
            </div>
            <div
                v-if="profile.user_personal_stories && profile.user_personal_stories.length <= idx"
                class="unwritten-story-wrapper bg-white m-l-20 m-r-20 m-b-16"
                @click="onClickWrite(idx)"
            >
                <div class="story-title f-14 f-medium p-t-22 m-l-16">{{ item.title }}</div>
                <div class="story-content f-14 m-t-12 m-l-16 p-b-22">터치해서 질문을 선택해주세요.</div>
            </div>
        </div>
        <div class="requirement f-13 m-l-20 m-t-12">*답변 3개는 필수에요.</div>
    </div>
</template>

<script>
// import SelectInput from '../app/SelectInput'
import profileService from '@/services/profile'

export default {
    name: 'EditPersonalStories',
    props: {
        profileUpdate: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        showInput: false,
        userPersonalStories: null,
        personalStories: null,
    }),
    computed: {
        profile() {
            return this.profileUpdate
        },
        storyExamples() {
            return [
                {
                    key: 1,
                    title: '첫번째 스토리',
                    required: true,
                },
                {
                    key: 2,
                    title: '두번째 스토리',
                    required: true,
                },
                {
                    key: 3,
                    title: '세번째 스토리',
                    required: true,
                },
            ]
        },
        // educationStatus() {
        //     const res = Object.keys(this.$profile.userEduStatus).map(key => this.$profile.userEduStatus[key])
        //     return this.type === 'high_school' ? res.filter(item => item.highSchool) : res
        // },
    },
    mounted() {
        setTimeout(() => {
            this.init()
        }, 100)
    },
    methods: {
        async init() {
            await this.$store.dispatch('loadProfile')

            await profileService.userPersonalStories(this.$store.getters.me.id).then(res => {
                this.userPersonalStories = res.data
            })

            this.personalStories = await profileService.personalStories()
        },
        // onClickSelector(id) {
        //     this.selectedShowMethod = id
        // },
        // setSelectedEduStatus(item) {
        //     this.educationStatusSelected = item
        // },

        async onClickModify(idx) {
            const userPersonalStory = this.profile.user_personal_stories[idx]
            await this.$modal.custom({
                component: 'ModalStoryWriter',
                options: {
                    mode: 'modify',
                    profile: this.profile,
                    headerTitle: '퍼스널 스토리 작성하기',
                    stories: this.personalStories,
                    userPersonalStory: userPersonalStory,
                },
            })
        },

        async onClickWrite(idx) {
            await this.$modal.custom({
                component: 'ModalStorySelector',
                options: {
                    profile: this.profile,
                    headerTitle: '질문 선택하기',
                    stories: this.personalStories,
                },
            })

            // this.$stackRouter.push({
            //     name: 'StorySelector',

            //     props: {
            //         profile: this.profile,
            //         headerTitle: "질문 선택하기",
            //         stories: this.personalStories,
            //         userPersonalStory: null,
            //     },
            // })
        },
        async submit() {
            try {
                this.$store.dispatch('loadProfile')
                this.$store.dispatch('loadMe')
                this.$stackRouter.pop()
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.edit-profile-detail {
    padding-bottom: 0px;

    .title {
        color: #787878;
        font-size: 15px;
        margin: 36px 0 12px 20px;
        font-weight: 400;
        text-align: left;
        @include spoqa-f-regular;
    }

    .description {
        color: #787878;
        line-height: 19.5px;
    }

    .requirement {
        color: #f64f56;
    }

    .written-story-icon {
        position: relative;
        z-index: 1;
        float: right;
        top: -6px;
        right: 14px;
    }

    .written-story-wrapper {
        color: #8a8a8a;
        background: #fdfcfa;
        border: 0.5px solid #151360;
        border-radius: 8px;
        position: relative;

        .story-title {
            color: #151360;
            font-weight: 600;
        }
        .story-content {
            color: #5c5c77;
            margin: 12px 16px 22px 16px;
        }
    }

    .unwritten-story-icon {
        position: relative;
        z-index: 1;
        float: right;
        top: -6px;
        right: 14px;
    }

    .unwritten-story-wrapper {
        color: #8a8a8a;
        border: 0.5px dashed #f64f56;
        border-radius: 8px;
        position: relative;
    }
    // .year {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;

    //     ::v-deep .textarea-with-x {
    //         height: 48px;
    //     }
    // }
}
</style>
