const dummyProfile = {
    id: 879,
    smoking: false,
    nickname: null,
    is_changed_nickname: true,
    birthday: '1993-12-21',
    height: 178,
    intro:
        '안녕하세요😄\n\n생명과학 전공으로 학위과정 4년차 통합과정 밟고 있습니다! 연구를 하는 특성 상 시간을 자유롭게 쓰는 편인데, 별일이 없다면 실험하는 것에 열정을 꽤 쏟고 있고, 제 일에 꽤 자부심을 가지고 있습니다.\n\n저는 서울 강북쪽에 살고 있고, 운동하는 것과 여기저기 맛집 다니는 것을 좋아합니다. 먹기 위해서는 운동이 필수죠! 같이 맛있는 것 먹고 운동해요ㅎㅎㅎ\n\n성격은 esfj로 외향적이고 긍정적인 편이고, 그래서인지 서로 밝은 에너지 나눌 수 있는 분 만나고 싶습니다.\n\n감사합니다.',
    kind: '',
    shape: '적당한 근육',
    mbti: 'ESFP',
    pet: '키우지않아요',
    car: null,
    job_description: null,
    bachelor_shorten: '고려대 생명과학대학 생명과학부',
    college_shorten: null,
    marry: false,
    exercise_freq: '주 3~4회',
    exercise_type: '헬스,축구',
    drink_freq: '가끔 마셔요',
    drink_style: '맥주',
    income: 2000,
    asset: 1700,
    degree: null,
    marry_plan: '3~4년 정도 연애하고 결혼하고 싶어요',
    child_plan: '갖는걸 선호하지만, 배우자와 상의해서 정할 수 있어요',
    career_plan: '계속 일을 할거에요',
    car_brand: '',
    car_type: '',
    car_model: '',
    house_style: '아파트',
    house_payment: '전세',
    finance: '',
    hometown_state_id: null,
    hometown_state_name: null,
    hometown_region_id: null,
    hometown_region_name: null,
    hometown_station_id: null,
    hometown_station_name: null,
    hometown_shorten: null,
    family_asset: 100000,
    family_intro: '',
    master_shorten: '고려대 일반대학원 생명과학부',
    doctor_shorten: '고려대 일반대학원 생명과학부',
    company_department: null,
    company_task: null,
    bachelor_u_id: 3,
    bachelor_u_name: '고려대',
    bachelor_c_id: 48,
    bachelor_c_name: '생명과학대학',
    bachelor_m_id: 29,
    bachelor_m_name: '생명과학부',
    master_u_id: 3,
    master_u_name: '고려대',
    master_c_id: 33,
    master_c_name: '일반대학원',
    master_m_id: 29,
    master_m_name: '생명과학부',
    doctor_u_id: 3,
    doctor_u_name: '고려대',
    doctor_c_id: 33,
    doctor_c_name: '일반대학원',
    doctor_m_id: 29,
    doctor_m_name: '생명과학부',
    high_school_name: '경성고등학교',
    health_status: 0,
    health_detail: '',
    high_school_status: 0,
    high_school_start: 2009,
    high_school_end: 2012,
    bachelor_status: 0,
    bachelor_start: 2012,
    bachelor_end: 2018,
    master_status: 1,
    master_start: 2018,
    master_end: 2024,
    doctor_status: 1,
    doctor_start: 2018,
    doctor_end: 2024,
    asset_detail: null,
    house_owner: null,
    only_to_manager: null,
    parent_status: 0,
    brotherhood_status: null,
    brother_count: 2,
    sister_count: 0,
    order_of_children: 1,
    religion: '무교',
    state: {
        id: 1,
        name: '서울',
        is_station: true,
        created_at: '2019-12-12T18:00:00.000+09:00',
        updated_at: '2019-12-12T18:00:00.000+09:00',
    },
    station: {
        id: 287,
        region: '서울',
        district: '성북구',
        line: '6',
        etc: null,
        name: '돌곶이',
        created_at: '2017-05-31T22:40:50.000+09:00',
        updated_at: '2018-08-01T12:35:12.000+09:00',
        alternative_state_ids: '[1]',
        latitude: '37.610538',
        longitude: '127.056403',
    },
    hometown_state: null,
    profile_options: [
        {
            id: 5671,
            profile_option_id: 2,
            ptype: 'shape',
        },
        {
            id: 5672,
            profile_option_id: 56,
            ptype: 'religion',
        },
        {
            id: 5673,
            profile_option_id: 144,
            ptype: 'marry_plan',
        },
        {
            id: 5674,
            profile_option_id: 74,
            ptype: 'mbti',
        },
        {
            id: 5675,
            profile_option_id: 92,
            ptype: 'exercise_freq',
        },
        {
            id: 5676,
            profile_option_id: 97,
            ptype: 'exercise_type',
        },
        {
            id: 5677,
            profile_option_id: 98,
            ptype: 'exercise_type',
        },
        {
            id: 5678,
            profile_option_id: 107,
            ptype: 'drink_freq',
        },
        {
            id: 5679,
            profile_option_id: 110,
            ptype: 'drink_style',
        },
        {
            id: 5680,
            profile_option_id: 77,
            ptype: 'pet',
        },
        {
            id: 5681,
            profile_option_id: 192,
            ptype: 'house_style',
        },
        {
            id: 5682,
            profile_option_id: 190,
            ptype: 'house_payment',
        },
        {
            id: 5683,
            profile_option_id: 148,
            ptype: 'child_plan',
        },
        {
            id: 5684,
            profile_option_id: 151,
            ptype: 'career_plan',
        },
    ],
    school: {
        id: 5,
        name: '대학원(박사) 재학',
        is_student: true,
        is_university: false,
        created_at: '2017-05-31T22:40:54.000+09:00',
        updated_at: '2017-05-31T22:40:54.000+09:00',
    },
    school_type: {
        id: 14,
        name: '고등학교',
        verification_required: false,
        spec_grade: 'D',
        created_at: '2017-05-31T22:40:54.000+09:00',
        updated_at: '2017-05-31T22:40:54.000+09:00',
    },
    university: null,
    major: null,
    job_category: {
        id: 4,
        name: '공기업/공공기관',
        position: 3,
        verification_required: true,
        created_at: '2017-05-31T22:41:03.000+09:00',
        updated_at: '2017-05-31T22:41:03.000+09:00',
    },
    job_type: null,
    job_id: 0,
    job_name: null,
    company_id: 0,
    company_name: null,
}
const privacySetting = {
    id: 514,
    user_id: 880,
    show_height: 1,
    show_region_name: 1,
    show_mbti: 1,
    show_exercise_freq: 1,
    show_exercise_type: 1,
    show_smoking: 1,
    show_drink_freq: 1,
    show_drink_style: 1,
    show_religion_name: 1,
    show_pet: 1,
    show_intro: 1,
    show_health_status: 0,
    show_health_detail: 0,
    show_high_school_name: 0,
    show_high_school_status: 0,
    show_high_school_start: 0,
    show_high_school_end: 0,
    show_bachelor_u_name: 1,
    show_bachelor_c_name: 0,
    show_bachelor_m_name: 0,
    show_bachelor_status: 1,
    show_bachelor_start: 1,
    show_bachelor_end: 1,
    show_master_u_name: 1,
    show_master_c_name: 0,
    show_master_m_name: 0,
    show_master_status: 1,
    show_master_start: 1,
    show_master_end: 1,
    show_doctor_u_name: 1,
    show_doctor_c_name: 0,
    show_doctor_m_name: 0,
    show_doctor_status: 1,
    show_doctor_start: 1,
    show_doctor_end: 1,
    show_income: 0,
    show_asset: 0,
    show_asset_detail: 1,
    show_house_style: 1,
    show_house_payment: 1,
    show_house_owner: null,
    show_car_brand: 1,
    show_car_type: 1,
    show_car_model: 1,
    show_finance: 1,
    show_hometown_region_name: 1,
    show_hometown_shorten: 1,
    show_family_asset: 0,
    show_family_intro: 1,
    show_parent_status: 0,
    show_brotherhood_status: 1,
    show_brother_count: 1,
    show_sister_count: 1,
    show_order_of_children: 1,
    show_marry_plan: 1,
    show_child_plan: 1,
    show_career_plan: 1,
    show_like_tags: 1,
    show_photos: 1,
    show_only_to_manager: 0,
    created_at: '2021-12-20T14:03:25.000+09:00',
    updated_at: '2021-12-24T13:23:31.000+09:00',
}
const dummyCompany = [
    {
        company_department: '전략기획실',
        company_id: 2,
        company_name: 'SK하이닉스',
        company_rank: '대장',
        company_region_id: null,
        company_state_id: 1,
        company_state_name: '서울',
        company_station_id: 105,
        company_task: '경영전략',
        employment_status: 1,
        employment_type: 0,
        job_category_id: 2,
        job_category_name: '대기업',
        job_name: '회계사',
        region_shorten: '서울 서초구(서초)',
        show_company_department: true,
        show_company_name: null,
        show_company_rank: true,
        show_region_shorten: true,
    },
    {
        job_category_name: '대학원생',
        job_category_id: 1,
        company_task: '대사질환 연구\n\n배고파영....올리브영',
        employment_status: 0,
    },
]
const dummyFamily = [
    {
        family_type: 0,
        live_status: 1,
        education_status: 3,
        university_id: null,
        university_name: null,
        company_id: null,
        company_name: null,
        job_id: null,
        job_name: '직장인',
        job_status: 1,
        brotherhood_status: null,
        show_live_status: 0,
        show_education_status: 1,
        show_university_name: 1,
        show_company_name: 0,
        show_job_name: 1,
        show_job_status: 1,
        show_brotherhood_status: 1,
    },
    {
        family_type: 1,
        live_status: 1,
        education_status: 2,
        university_id: null,
        university_name: null,
        company_id: null,
        company_name: '삼성화재',
        job_id: null,
        job_name: '직장인',
        job_status: 1,
        brotherhood_status: null,
        show_live_status: 0,
        show_education_status: 1,
        show_university_name: 1,
        show_company_name: 0,
        show_job_name: 1,
        show_job_status: 1,
        show_brotherhood_status: 1,
    },
    {
        // id: 638,
        // user_id: 880,
        family_type: 2,
        live_status: null,
        education_status: 2,
        university_id: null,
        university_name: null,
        company_id: null,
        company_name: 'KAIST',
        job_id: null,
        job_name: '대학생',
        job_status: 1,
        brotherhood_status: '남동생',
        show_live_status: 0,
        show_education_status: 1,
        show_university_name: 1,
        show_company_name: 0,
        show_job_name: 1,
        show_job_status: 1,
        show_brotherhood_status: 1,
    },
]

export function createDummyProfile(profile) {
    const {
        id,
        birthday,
        company: profileCompany,
        family: profileFamily,
        privacy_setting: { id: privacySettingId },
    } = profile
    const company = profileCompany && profileCompany.length ? profileCompany : dummyCompany
    const family = profileFamily && profileFamily.length ? profileFamily : dummyFamily
    return {
        ...dummyProfile,
        privacy_setting: {
            ...privacySetting,
            id: privacySettingId,
            user_id: id,
        },
        id: id,
        birthday: birthday,
        company,
        family,
    }
}
